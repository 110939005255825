import React, { useState, useCallback, useEffect } from "react";

import format from "date-fns/format";
import isToday from "date-fns/isToday";

import {
  DateNavigation,
  CollectionModal,
  ApprovedTable,
  useTableFilter,
} from "../../../components";
import { Resident } from "../../../classes/resident";
import { Location } from "../../../classes/location";
import { Collection, CollectionStatusTypes } from "../../../classes/collection";
import {
  useCollectionsApi,
  GetCollectionsInputType,
  GetCollectionsOutputType,
} from "../../../apis/collections";

export default function Approved() {
  const { getCollections } = useCollectionsApi();
  const {
    collectionLocations,
    checksOnly,
    hideGeoPayments,
    hideDirectDepositPayments,
  } = useTableFilter();
  const [currentDate, setCurrentDate] = useState(new Date());

  const [active, setActive] = useState(false);
  const [collection, setCollection] = useState(new Collection());
  const [resident, setResident] = useState(new Resident());
  const [collections, setCollections] = useState<GetCollectionsOutputType>({
    items: [],
    total: 0,
    overallBalance: 0,
    paymentSum: 0,
  });
  const [residentCollections, setResidentCollections] =
    useState<GetCollectionsOutputType>({
      items: [],
      total: 0,
      overallBalance: 0,
      paymentSum: 0,
    });

  const [page, setPage] = useState(0);
  const [take] = useState(100);

  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    const customQuery: GetCollectionsInputType = {
      skip: page * take,
      take,
      statuses: [CollectionStatusTypes.APPROVED],
      dateApproved__eq: format(currentDate, "yyyy-MM-dd"),
    };

    if (collectionLocations.length > 0) {
      customQuery.locations = collectionLocations;
    }

    if (checksOnly) {
      customQuery.checksOnly = checksOnly;
    }

    if (hideGeoPayments) {
      customQuery.hideGeoPayments = hideGeoPayments;
    }

    if (hideDirectDepositPayments) {
      customQuery.hideDirectDepositPayments = hideDirectDepositPayments;
    }

    if (search) {
      customQuery.search = search;
    }

    const _collections = await getCollections({ ...customQuery });
    if (page === 0) {
      setCollections(_collections);
    } else {
      setCollections({
        items: [...collections.items, ..._collections.items],
        total: _collections.total,
        overallBalance: _collections.overallBalance,
        paymentSum: _collections.paymentSum,
      });
    }
  }, [
    page,
    take,
    currentDate,
    collectionLocations,
    checksOnly,
    hideGeoPayments,
    hideDirectDepositPayments,
    search,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = collections.items.map((collection) => ({
    collection: new Collection(collection),
    resident: new Resident(collection.resident),
    location: new Location((collection.resident as Resident).location),
  }));

  const handleView = async (row: {
    collection?: Collection;
    resident?: Resident;
  }) => {
    setCollection(row.collection as Collection);
    setResident(row.resident as Resident);

    // Fetch all of the resident's collections
    // because we need it for "Overall Balance"
    const _residentCollections = await getCollections({
      residentId: (row.resident as Resident).id as number,
    });

    setResidentCollections(_residentCollections);

    setActive(true);
  };

  return (
    <>
      <DateNavigation
        date={currentDate}
        onChange={(date: Date) => {
          setPage(0);
          setCurrentDate(date);
        }}
        rightDisabled={() => isToday(currentDate)}
      />

      <ApprovedTable
        rows={rows}
        total={collections.total}
        setPage={setPage}
        paymentSum={collections.paymentSum}
        search={search}
        setSearch={setSearch}
        onView={handleView}
        fetchData={fetchData}
      />

      <CollectionModal
        active={active}
        closeModal={() => setActive(false)}
        collection={collection}
        collections={residentCollections}
        resident={resident}
        onCallback={fetchData}
      />
    </>
  );
}
