import React, { useContext, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  Typography,
  Button,
  Grid,
  ListItemButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { ACTIVITY_TYPES } from "../classes/activity";
import {
  MOVED_IN,
  MOVED_OUT,
  PENDING,
  REQUEST_MOVE_IN,
} from "../classes/resident";
import { Location } from "../classes/location";
import { GetLocationsOutputType, useLocationsApi } from "../apis/locations";
import { GetDistrictsOutputType, useDistrictsApi } from "../apis/districts";
import { GetUsersOutputType, useUsersApi } from "../apis/users";
import { sortLocations } from "../utils/sortLocations";
import { District, User } from "../classes";
import {
  WORKORDER_CATEGORIES,
  WORKORDER_PRIORITIES,
  WORKORDER_STATUSES,
} from "../classes/work-order";

interface TableFilterContextType {
  filterModalActive: boolean;
  setFilterModalActive: (active: boolean) => void;

  status: string[];
  setStatus: (status: string[]) => void;

  isGeo: boolean;
  setIsGeo: (isGeo: boolean) => void;

  hasNoRentDueDate: boolean;
  setHasNoRentDueDate: (hasNoRentDueDate: boolean) => void;

  locations: number[];
  setLocations: (locations: number[]) => void;

  districts: number[];
  setDistricts: (districts: number[]) => void;

  collectionLocations: (number | string)[];
  setCollectionLocations: (collectionLocations: (number | string)[]) => void;

  activityTypes: string[];
  setActivityTypes: (activityTypes: string[]) => void;

  checksOnly: boolean;
  setChecksOnly: (checksOnly: boolean) => void;

  hideGeoPayments: boolean;
  setHideGeoPayments: (hideGeoPayments: boolean) => void;

  hideDirectDepositPayments: boolean;
  setHideDirectDepositPayments: (hideDirectDepositPayments: boolean) => void;

  sortLastUaDate: boolean;
  setSortLastUaDate: (sortLastUaDate: boolean) => void;

  workOrderPriorities: string[];
  setWorkOrderPriorities: (workOrderPriorities: string[]) => void;

  workOrderStatuses: string[];
  setWorkOrderStatuses: (workOrderStatuses: string[]) => void;

  workOrderCategories: string[];
  setWorkOrderCategories: (workOrderCategories: string[]) => void;
}

export const TableFilterContext = React.createContext<TableFilterContextType>(
  {} as TableFilterContextType
);
export const useTableFilter = () => useContext(TableFilterContext);
export const TableFilterProvider = TableFilterContext.Provider;

export interface Filter {
  component: ({ callback }: { callback: () => void }) => JSX.Element;
  isActive: () => boolean;
}

export const ResidentFilter: Filter = {
  component: ({ callback }: { callback: () => void }) => {
    const {
      isGeo,
      setIsGeo: _setIsGeo,

      hasNoRentDueDate,
      setHasNoRentDueDate: _setHasNoRentDueDate,
    } = useTableFilter();

    const setIsGeo = useCallback(
      (_isGeo: boolean) => {
        _setIsGeo(_isGeo);
        callback && callback();
      },
      [_setIsGeo, callback]
    );

    const setHasNoRentDueDate = useCallback(
      (_hasNoRentDueDate: boolean) => {
        _setHasNoRentDueDate(_hasNoRentDueDate);
        callback && callback();
      },
      [_setHasNoRentDueDate, callback]
    );

    return (
      <Accordion key="resident">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Resident</Typography>
            {(isGeo || hasNoRentDueDate) && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              style={{ height: 48 }}
            >
              <Typography>Is GEO?</Typography>
              <Switch
                checked={isGeo}
                onChange={(e) => setIsGeo(e.target.checked)}
              />
            </Grid>

            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              style={{ height: 48 }}
            >
              <Typography>Has No Rent Due Date?</Typography>
              <Switch
                checked={hasNoRentDueDate}
                onChange={(e) => setHasNoRentDueDate(e.target.checked)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { isGeo, hasNoRentDueDate } = useTableFilter();
    return isGeo || hasNoRentDueDate;
  },
};

export const ResidentLastUaSort: Filter = {
  component: ({ callback }: { callback: () => void }) => {
    const { sortLastUaDate, setSortLastUaDate: _setSortLastUaDate } =
      useTableFilter();

    const setSortLastUaDate = useCallback(
      (_sortLastUaDate: boolean) => {
        _setSortLastUaDate(_sortLastUaDate);
        callback && callback();
      },
      [_setSortLastUaDate, callback]
    );

    return (
      <Accordion key="residentLastUaSort">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Resident Sort</Typography>
            {sortLastUaDate && <FiberManualRecordIcon fontSize="small" />}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              style={{ height: 48 }}
            >
              <Typography>Sort by Last UA Date?</Typography>
              <Switch
                checked={sortLastUaDate}
                onChange={(e) => setSortLastUaDate(e.target.checked)}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { sortLastUaDate } = useTableFilter();
    return sortLastUaDate;
  },
};

export const ResidentStatusFilter: Filter = {
  component: () => {
    const { status, setStatus } = useTableFilter();

    const onClick = useCallback(
      (_status: string) => {
        setStatus([_status]);
      },
      [setStatus]
    );

    return (
      <Accordion key="residentStatus">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Resident Status</Typography>
            {status && <FiberManualRecordIcon fontSize="small" />}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem onClick={() => onClick(PENDING)} dense disableGutters>
              <Checkbox checked={status.includes(PENDING)} />
              <ListItemText primary="Pending" />
            </ListItem>

            <ListItem
              onClick={() => onClick(REQUEST_MOVE_IN)}
              dense
              disableGutters
            >
              <Checkbox checked={status.includes(REQUEST_MOVE_IN)} />
              <ListItemText primary="Request Move-in" />
            </ListItem>

            <ListItem onClick={() => onClick(MOVED_IN)} dense disableGutters>
              <Checkbox checked={status.includes(MOVED_IN)} />
              <ListItemText primary="Moved In" />
            </ListItem>

            <ListItem onClick={() => onClick(MOVED_OUT)} dense disableGutters>
              <Checkbox checked={status.includes(MOVED_OUT)} />
              <ListItemText primary="Moved Out" />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { status } = useTableFilter();
    return status.length > 0;
  },
};

export const LocationFilter: Filter = {
  component: ({ callback }: { callback: () => void }) => {
    const { getLocations } = useLocationsApi();
    const { locations, setLocations: _setLocations } = useTableFilter();

    const setLocations = useCallback(
      (_locations: number[]) => {
        _setLocations(_locations);
        callback && callback();
      },
      [_setLocations, callback]
    );

    const [allLocations, setAllLocations] = useState<GetLocationsOutputType>({
      items: [],
      total: 0,
      totalAvailableBeds: 0,
    });

    const fetchLocations = useCallback(async () => {
      const _locations = await getLocations();
      _locations.items = sortLocations(_locations.items);
      setAllLocations(_locations);
    }, [getLocations]);

    useEffect(() => {
      fetchLocations();
    }, [fetchLocations]);

    const handleLocationListItemClick = (value: number) => {
      const index = locations.indexOf(value);
      let newLocations = [];
      if (index > -1) {
        newLocations = [
          ...locations.slice(0, index),
          ...locations.slice(index + 1),
        ];
      } else {
        newLocations = [...locations, value];
      }
      console.log("locations", newLocations);
      setLocations(newLocations);
    };

    const clearLocations = () => {
      setLocations([]);
    };

    const selectedLocations = allLocations.items
      .filter((l) => locations.includes(l.id as number))
      .map((location) => location.name)
      .join(", ");

    return (
      <Accordion key="location">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Locations</Typography>
            {locations.length > 0 && <FiberManualRecordIcon fontSize="small" />}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body2">
                  Selected Locations: {selectedLocations}
                </Typography>
              </Grid>

              <Grid item>
                <Button onClick={clearLocations}>Clear All</Button>
              </Grid>

              <Grid item>
                <List>
                  {allLocations.items.map(({ id, name }: Location) => (
                    <ListItem
                      key={id as number}
                      onClick={() => handleLocationListItemClick(id as number)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!locations.find((location) => location === id)
                        }
                      />
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { locations } = useTableFilter();
    return locations.length > 0;
  },
};

export const DistrictFilter: Filter = {
  component: ({ callback }: { callback: () => void }) => {
    const { getDistricts } = useDistrictsApi();
    const { districts, setDistricts: _setDistricts } = useTableFilter();

    const setDistricts = useCallback(
      (_districts: number[]) => {
        _setDistricts(_districts);
        callback && callback();
      },
      [_setDistricts, callback]
    );

    const [allDistricts, setAllDistricts] = useState<GetDistrictsOutputType>({
      items: [],
      total: 0,
    });

    const fetchDistricts = useCallback(async () => {
      const _districts = await getDistricts();
      setAllDistricts(_districts);
    }, [getDistricts]);

    useEffect(() => {
      fetchDistricts();
    }, [fetchDistricts]);

    const handleDistrictListItemClick = (value: number) => {
      const index = districts.indexOf(value);
      let newDistricts = [];
      if (index > -1) {
        newDistricts = [
          ...districts.slice(0, index),
          ...districts.slice(index + 1),
        ];
      } else {
        newDistricts = [...districts, value];
      }
      console.log("districts", newDistricts);
      setDistricts(newDistricts);
    };

    const clearDistricts = () => {
      setDistricts([]);
    };

    const selectedDistricts = allDistricts.items
      .filter((d) => districts.includes(d.id as number))
      .map((district) => district.name)
      .join(", ");

    return (
      <Accordion key="district">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Districts</Typography>
            {districts.length > 0 && <FiberManualRecordIcon fontSize="small" />}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body2">
                  Selected Districts: {selectedDistricts}
                </Typography>
              </Grid>

              <Grid item>
                <Button onClick={clearDistricts}>Clear All</Button>
              </Grid>

              <Grid item>
                <List>
                  {allDistricts.items.map(({ id, name }: District) => (
                    <ListItem
                      key={id as number}
                      onClick={() => handleDistrictListItemClick(id as number)}
                      dense
                      disableGutters
                    >
                      <Checkbox checked={!!districts.find((d) => d === id)} />
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { districts } = useTableFilter();
    return districts.length > 0;
  },
};

export const ManagersFilter: Filter = {
  component: ({ callback }: { callback: () => void }) => {
    const { getUsers } = useUsersApi();
    const { setLocations: _setLocations } = useTableFilter();

    const setLocations = useCallback(
      (_locations: number[]) => {
        _setLocations(_locations);
        callback && callback();
      },
      [_setLocations, callback]
    );

    const [allManagers, setAllManagers] = useState<GetUsersOutputType>({
      items: [],
      total: 0,
    });

    const fetchManagers = useCallback(async () => {
      const _managers = await getUsers();
      setAllManagers(_managers);
    }, [getUsers]);

    useEffect(() => {
      fetchManagers();
    }, [fetchManagers]);

    const handleManagerListItemClick = (manager: any) => {
      const locationIds = manager.locations.map(
        (location: Location) => location.id
      );
      setLocations(locationIds);
    };

    return (
      <Accordion key="managers">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Managers</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="caption">
                  Select Locations by Managers
                </Typography>
                <List>
                  {allManagers.items.map((manager: User) => (
                    <ListItemButton
                      key={manager.id}
                      onClick={() => handleManagerListItemClick(manager)}
                    >
                      <ListItemText
                        primary={`${manager.firstName} ${manager.lastName}`}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    return false;
  },
};

export const CollectionManagersFilter: Filter = {
  component: ({ callback }: { callback: () => void }) => {
    const { getUsers } = useUsersApi();
    const { setCollectionLocations: _setCollectionLocations } =
      useTableFilter();

    const setCollectionLocations = useCallback(
      (_collectionLocations: number[]) => {
        _setCollectionLocations(_collectionLocations);
        callback && callback();
      },
      [_setCollectionLocations, callback]
    );

    const [allManagers, setAllManagers] = useState<GetUsersOutputType>({
      items: [],
      total: 0,
    });

    const fetchManagers = useCallback(async () => {
      const _managers = await getUsers();
      setAllManagers(_managers);
    }, [getUsers]);

    useEffect(() => {
      fetchManagers();
    }, [fetchManagers]);

    const handleManagerListItemClick = (manager: any) => {
      const locationIds = manager.locations.map(
        (location: Location) => location.id
      );
      setCollectionLocations(locationIds);
    };

    return (
      <Accordion key="managers">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Managers</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="caption">
                  Select Locations by Managers
                </Typography>
                <List>
                  {allManagers.items.map((manager: User) => (
                    <ListItemButton
                      key={manager.id}
                      onClick={() => handleManagerListItemClick(manager)}
                    >
                      <ListItemText
                        primary={`${manager.firstName} ${manager.lastName}`}
                      />
                    </ListItemButton>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    return false;
  },
};

export const CollectionLocationFilter: Filter = {
  component: ({ callback }: { callback: () => void }) => {
    const { getLocations } = useLocationsApi();
    const {
      collectionLocations,
      setCollectionLocations: _setCollectionLocations,
    } = useTableFilter();

    const setCollectionLocations = useCallback(
      (_collectionLocations: (number | string)[]) => {
        _setCollectionLocations(_collectionLocations);
        callback && callback();
      },
      [_setCollectionLocations, callback]
    );

    const [allLocations, setAllLocations] = useState<GetLocationsOutputType>({
      items: [],
      total: 0,
      totalAvailableBeds: 0,
    });

    const fetchLocations = useCallback(async () => {
      const _collectionLocations = await getLocations();
      _collectionLocations.items = sortLocations(_collectionLocations.items);
      setAllLocations(_collectionLocations);
    }, [getLocations]);

    useEffect(() => {
      fetchLocations();
    }, [fetchLocations]);

    const handleListItemClick = (value: number | string) => {
      const index = collectionLocations.indexOf(value);
      let newCollectionLocations = [];
      if (index > -1) {
        newCollectionLocations = [
          ...collectionLocations.slice(0, index),
          ...collectionLocations.slice(index + 1),
        ];
      } else {
        newCollectionLocations = [...collectionLocations, value];
      }

      console.log("collectionLocations", newCollectionLocations);
      setCollectionLocations(newCollectionLocations);
    };

    const clearLocations = () => {
      setCollectionLocations([]);
    };

    return (
      <Accordion key="collection-location">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Locations</Typography>
            {collectionLocations.length > 0 && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column">
              <Grid item>
                <Button onClick={clearLocations}>Clear</Button>
              </Grid>
              <Grid>
                <List>
                  <ListItem
                    onClick={() => handleListItemClick("null")}
                    dense
                    disableGutters
                  >
                    <Checkbox
                      checked={
                        !!collectionLocations.some(
                          (location) => location === "null"
                        )
                      }
                    />
                    <ListItemText primary="Unassigned (Pending Residents)" />
                  </ListItem>
                  {allLocations.items.map(({ id, name }) => (
                    <ListItem
                      key={id as number}
                      onClick={() => handleListItemClick(id as number)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!collectionLocations.find(
                            (location) => location === id
                          )
                        }
                      />
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { collectionLocations } = useTableFilter();
    return collectionLocations.length > 0;
  },
};

export const ActivityTypeFilter: Filter = {
  component: ({ callback }) => {
    const { activityTypes, setActivityTypes: _setActivityTypes } =
      useTableFilter();

    const setActivityTypes = useCallback(
      (_activityTypes: string[]) => {
        _setActivityTypes(_activityTypes);
        callback && callback();
      },
      [_setActivityTypes, callback]
    );

    const options = [
      { text: "Request Move In", value: ACTIVITY_TYPES.REQUEST_MOVE_IN },
      { text: "Moved In", value: ACTIVITY_TYPES.MOVED_IN },
      { text: "Moved Out", value: ACTIVITY_TYPES.MOVED_OUT },
      { text: "Location Change", value: ACTIVITY_TYPES.LOCATION_CHANGE },
      { text: "Payment Submitted", value: ACTIVITY_TYPES.PAYMENT_SUBMITTED },
      { text: "Payment Rejected", value: ACTIVITY_TYPES.PAYMENT_REJECTED },
      { text: "Payment Approved", value: ACTIVITY_TYPES.PAYMENT_APPROVED },
      { text: "UA Negative", value: ACTIVITY_TYPES.UA_NEGATIVE },
      { text: "UA Positive", value: ACTIVITY_TYPES.UA_POSITIVE },
      { text: "UA Change Negative", value: ACTIVITY_TYPES.UA_CHANGE_NEGATIVE },
      { text: "UA Change Positive", value: ACTIVITY_TYPES.UA_CHANGE_POSITIVE },
      { text: "UA Delete", value: ACTIVITY_TYPES.UA_DELETE },
      {
        text: "Resident Photo Added",
        value: ACTIVITY_TYPES.RESIDENT_PHOTO_ADDED,
      },
      { text: "Resident Report", value: ACTIVITY_TYPES.RESIDENT_REPORT },
    ];

    const handleListItemClick = (value: string) => {
      const index = activityTypes.indexOf(value);
      let newActivityTypes = [];
      if (index > -1) {
        newActivityTypes = [
          ...activityTypes.slice(0, index),
          ...activityTypes.slice(index + 1),
        ];
      } else {
        newActivityTypes = [...activityTypes, value];
      }
      console.log("activityTypes", newActivityTypes);
      setActivityTypes(newActivityTypes);
    };

    const clearActivityTypes = () => {
      setActivityTypes([]);
    };

    return (
      <Accordion key="activityType">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Activity Types</Typography>
            {activityTypes.length > 0 && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column">
              <Grid item>
                <Button onClick={clearActivityTypes}>Clear</Button>
              </Grid>
              <Grid>
                <List>
                  {options.map(({ text, value }) => (
                    <ListItem
                      key={value}
                      onClick={() => handleListItemClick(value)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!activityTypes.find(
                            (activityType) => activityType === value
                          )
                        }
                      />
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { activityTypes } = useTableFilter();
    return activityTypes.length > 0;
  },
};

export const AuditFilter: Filter = {
  component: ({ callback }) => {
    const { checksOnly, setChecksOnly: _setChecksOnly } = useTableFilter();
    const { hideGeoPayments, setHideGeoPayments: _setHideGeoPayments } =
      useTableFilter();
    const {
      hideDirectDepositPayments,
      setHideDirectDepositPayments: _setHideDirectDepositPayments,
    } = useTableFilter();

    const setChecksOnly = useCallback(
      (_checksOnly: boolean) => {
        _setChecksOnly(_checksOnly);
        callback && callback();
      },
      [_setChecksOnly, callback]
    );

    const setHideGeoPayments = useCallback(
      (_hideGeoPayments: boolean) => {
        _setHideGeoPayments(_hideGeoPayments);
        callback && callback();
      },
      [_setHideGeoPayments, callback]
    );

    const setHideDirectDepositPayments = useCallback(
      (_hideDirectDepositPayments: boolean) => {
        _setHideDirectDepositPayments(_hideDirectDepositPayments);
        callback && callback();
      },
      [_setHideDirectDepositPayments, callback]
    );

    return (
      <Accordion key="checksOnly">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Audit</Typography>
            {(checksOnly || hideGeoPayments || hideDirectDepositPayments) && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Check Only</Typography>
            <Switch
              checked={checksOnly}
              onChange={(e) => setChecksOnly(e.target.checked)}
            />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Hide GEO Payments</Typography>
            <Switch
              checked={hideGeoPayments}
              onChange={(e) => setHideGeoPayments(e.target.checked)}
            />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Hide Direct Deposit Payments</Typography>
            <Switch
              checked={hideDirectDepositPayments}
              onChange={(e) => setHideDirectDepositPayments(e.target.checked)}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },

  isActive: () => {
    const { checksOnly, hideGeoPayments, hideDirectDepositPayments } =
      useTableFilter();

    return checksOnly || hideGeoPayments || hideDirectDepositPayments;
  },
};

export const ApprovedFilter: Filter = {
  component: ({ callback }) => {
    const { checksOnly, setChecksOnly: _setChecksOnly } = useTableFilter();
    const { hideGeoPayments, setHideGeoPayments: _setHideGeoPayments } =
      useTableFilter();
    const {
      hideDirectDepositPayments,
      setHideDirectDepositPayments: _setHideDirectDepositPayments,
    } = useTableFilter();

    const setChecksOnly = useCallback(
      (_checksOnly: boolean) => {
        _setChecksOnly(_checksOnly);
        callback && callback();
      },
      [_setChecksOnly, callback]
    );

    const setHideGeoPayments = useCallback(
      (_hideGeoPayments: boolean) => {
        _setHideGeoPayments(_hideGeoPayments);
        callback && callback();
      },
      [_setHideGeoPayments, callback]
    );

    const setHideDirectDepositPayments = useCallback(
      (_hideDirectDepositPayments: boolean) => {
        _setHideDirectDepositPayments(_hideDirectDepositPayments);
        callback && callback();
      },
      [_setHideDirectDepositPayments, callback]
    );

    return (
      <Accordion key="checksOnly">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Audit</Typography>
            {(checksOnly || hideGeoPayments || hideDirectDepositPayments) && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Check Only</Typography>
            <Switch
              checked={checksOnly}
              onChange={(e) => setChecksOnly(e.target.checked)}
            />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Hide GEO Payments</Typography>
            <Switch
              checked={hideGeoPayments}
              onChange={(e) => setHideGeoPayments(e.target.checked)}
            />
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Hide Direct Deposit Payments</Typography>
            <Switch
              checked={hideDirectDepositPayments}
              onChange={(e) => setHideDirectDepositPayments(e.target.checked)}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  },

  isActive: () => {
    const { checksOnly, hideGeoPayments, hideDirectDepositPayments } =
      useTableFilter();

    return checksOnly || hideGeoPayments || hideDirectDepositPayments;
  },
};

export const WorkOrderPrioritiesFilter: Filter = {
  component: ({ callback }) => {
    const {
      workOrderPriorities,
      setWorkOrderPriorities: _setWorkOrderPriorities,
    } = useTableFilter();

    const setWorkOrderPriorities = useCallback(
      (_workOrderPriorities: string[]) => {
        _setWorkOrderPriorities(_workOrderPriorities);
        callback && callback();
      },
      [_setWorkOrderPriorities, callback]
    );

    const options = [
      { text: "Low", value: WORKORDER_PRIORITIES.LOW },
      { text: "Medium", value: WORKORDER_PRIORITIES.MEDIUM },
      { text: "High", value: WORKORDER_PRIORITIES.HIGH },
      { text: "Emergency", value: WORKORDER_PRIORITIES.EMERGENCY },
    ];

    const handleListItemClick = (value: string) => {
      const index = workOrderPriorities.indexOf(value);
      let newWorkOrderPriorities = [];
      if (index > -1) {
        newWorkOrderPriorities = [
          ...workOrderPriorities.slice(0, index),
          ...workOrderPriorities.slice(index + 1),
        ];
      } else {
        newWorkOrderPriorities = [...workOrderPriorities, value];
      }

      setWorkOrderPriorities(newWorkOrderPriorities);
    };

    const handleClear = () => {
      setWorkOrderPriorities([]);
    };

    return (
      <Accordion key="Work Order Priorities">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Priority</Typography>
            {workOrderPriorities.length > 0 && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column">
              <Grid item>
                <Button onClick={handleClear}>Clear</Button>
              </Grid>
              <Grid>
                <List>
                  {options.map(({ text, value }) => (
                    <ListItem
                      key={value}
                      onClick={() => handleListItemClick(value)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!workOrderPriorities.find((wos) => wos === value)
                        }
                      />
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { workOrderPriorities } = useTableFilter();
    return workOrderPriorities.length > 0;
  },
};

export const WorkOrderStatusesFilter: Filter = {
  component: ({ callback }) => {
    const { workOrderStatuses, setWorkOrderStatuses: _setWorkOrderStatuses } =
      useTableFilter();

    const setWorkOrderStatuses = useCallback(
      (_workOrderStatuses: string[]) => {
        _setWorkOrderStatuses(_workOrderStatuses);
        callback && callback();
      },
      [_setWorkOrderStatuses, callback]
    );

    const options = [
      { text: "Pending", value: WORKORDER_STATUSES.PENDING },
      { text: "Active", value: WORKORDER_STATUSES.ACTIVE },
      { text: "Completed", value: WORKORDER_STATUSES.COMPLETED },
    ];

    const handleListItemClick = (value: string) => {
      const index = workOrderStatuses.indexOf(value);
      let newWorkOrderStatuses = [];
      if (index > -1) {
        newWorkOrderStatuses = [
          ...workOrderStatuses.slice(0, index),
          ...workOrderStatuses.slice(index + 1),
        ];
      } else {
        newWorkOrderStatuses = [...workOrderStatuses, value];
      }

      setWorkOrderStatuses(newWorkOrderStatuses);
    };

    const handleClear = () => {
      setWorkOrderStatuses([]);
    };

    return (
      <Accordion key="Work Order Statuses">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Status</Typography>
            {workOrderStatuses.length > 0 && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column">
              <Grid item>
                <Button onClick={handleClear}>Clear</Button>
              </Grid>
              <Grid>
                <List>
                  {options.map(({ text, value }) => (
                    <ListItem
                      key={value}
                      onClick={() => handleListItemClick(value)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!workOrderStatuses.find((wos) => wos === value)
                        }
                      />
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { workOrderStatuses } = useTableFilter();
    return workOrderStatuses.length > 0;
  },
};

export const WorkOrderCategoriesFilter: Filter = {
  component: ({ callback }) => {
    const {
      workOrderCategories,
      setWorkOrderCategories: _setWorkOrderCategories,
    } = useTableFilter();

    const setWorkOrderCategories = useCallback(
      (_workOrderCategories: string[]) => {
        _setWorkOrderCategories(_workOrderCategories);
        callback && callback();
      },
      [_setWorkOrderCategories, callback]
    );

    const options = [
      { text: "Appliance", value: WORKORDER_CATEGORIES.APPLIANCE },
      { text: "Cabinetry", value: WORKORDER_CATEGORIES.CABINETRY },
      { text: "Cleaning", value: WORKORDER_CATEGORIES.CLEANING },
      { text: "Concrete", value: WORKORDER_CATEGORIES.CONCRETE },
      { text: "Doors", value: WORKORDER_CATEGORIES.DOORS },
      { text: "Electrical", value: WORKORDER_CATEGORIES.ELECTRICAL },
      { text: "Excavation", value: WORKORDER_CATEGORIES.EXCAVATION },
      { text: "Extermination", value: WORKORDER_CATEGORIES.EXTERMINATION },
      { text: "Finish Work", value: WORKORDER_CATEGORIES.FINISH_WORK },
      { text: "Flooring", value: WORKORDER_CATEGORIES.FLOORING },
      { text: "Framing", value: WORKORDER_CATEGORIES.FRAMING },
      { text: "Furnishing", value: WORKORDER_CATEGORIES.FURNISHING },
      { text: "HVAC", value: WORKORDER_CATEGORIES.HVAC },
      { text: "Landscaping", value: WORKORDER_CATEGORIES.LANDSCAPING },
      { text: "Media", value: WORKORDER_CATEGORIES.MEDIA },
      { text: "Painting", value: WORKORDER_CATEGORIES.PAINTING },
      { text: "Plumbing", value: WORKORDER_CATEGORIES.PLUMBING },
      { text: "Preventative", value: WORKORDER_CATEGORIES.PREVENTATIVE },
      { text: "Roofing", value: WORKORDER_CATEGORIES.ROOFING },
      { text: "Tile", value: WORKORDER_CATEGORIES.TILE },
      { text: "Turnover", value: WORKORDER_CATEGORIES.TURNOVER },
      { text: "Utilities", value: WORKORDER_CATEGORIES.UTILITIES },
      { text: "Windows", value: WORKORDER_CATEGORIES.WINDOWS },
    ];

    const handleListItemClick = (value: string) => {
      const index = workOrderCategories.indexOf(value);
      let newWorkOrderCategories = [];
      if (index > -1) {
        newWorkOrderCategories = [
          ...workOrderCategories.slice(0, index),
          ...workOrderCategories.slice(index + 1),
        ];
      } else {
        newWorkOrderCategories = [...workOrderCategories, value];
      }

      setWorkOrderCategories(newWorkOrderCategories);
    };

    const handleClear = () => {
      setWorkOrderCategories([]);
    };

    return (
      <Accordion key="Work Order Categories">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography>Category</Typography>
            {workOrderCategories.length > 0 && (
              <FiberManualRecordIcon fontSize="small" />
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 300, overflow: "scroll" }}>
            <Grid container direction="column">
              <Grid item>
                <Button onClick={handleClear}>Clear</Button>
              </Grid>
              <Grid>
                <List>
                  {options.map(({ text, value }) => (
                    <ListItem
                      key={value}
                      onClick={() => handleListItemClick(value)}
                      dense
                      disableGutters
                    >
                      <Checkbox
                        checked={
                          !!workOrderCategories.find((wos) => wos === value)
                        }
                      />
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  },
  isActive: () => {
    const { workOrderCategories } = useTableFilter();
    return workOrderCategories.length > 0;
  },
};

interface TableFilterModalProps {
  filterContent: Filter[];
  callback: () => void;
}

export const TableFilterModal = ({
  filterContent,
  callback,
}: TableFilterModalProps) => {
  const { filterModalActive, setFilterModalActive } = useTableFilter();

  return (
    <Dialog
      open={filterModalActive}
      onClose={() => setFilterModalActive(false)}
    >
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>
        {filterContent.map((c) => c.component({ callback }))}
      </DialogContent>
    </Dialog>
  );
};

interface LocalStorageStateType {
  value: number | boolean | string[] | number[] | boolean[];
}

const useLocalStorageState = (
  key: string,
  defaultValue: string | number | boolean | string[] | number[] | boolean[]
) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  });

  const setValue = (
    value: (
      storedValue: LocalStorageStateType
    ) => LocalStorageStateType | LocalStorageStateType
  ) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

interface TableFilterProps {
  children: JSX.Element;
}

export const TableFilter = ({ children }: TableFilterProps) => {
  const [filterModalActive, setFilterModalActive] = useState(false);

  const [status, setStatus] = useState([MOVED_IN]);

  const [isGeo, setIsGeo] = useState(false);

  const [hasNoRentDueDate, setHasNoRentDueDate] = useState(false);

  const [locations, setLocations] = useLocalStorageState(
    "filter.locations",
    []
  );

  const [districts, setDistricts] = useLocalStorageState(
    "filter.districts",
    []
  );

  const [collectionLocations, setCollectionLocations] = useLocalStorageState(
    "filter.collectionLocations",
    []
  );

  const [activityTypes, setActivityTypes] = useLocalStorageState(
    "filter.activityTypes",
    []
  );

  const [checksOnly, setChecksOnly] = useState(false);

  const [hideGeoPayments, setHideGeoPayments] = useLocalStorageState(
    "filter.hideGeoPayments",
    false
  );

  const [hideDirectDepositPayments, setHideDirectDepositPayments] =
    useLocalStorageState("filter.hideDirectDepositPayments", false);

  const [sortLastUaDate, setSortLastUaDate] = useState(false);

  const [workOrderPriorities, setWorkOrderPriorities] = useLocalStorageState(
    "filter.workOrderPriorities",
    []
  );

  const [workOrderStatuses, setWorkOrderStatuses] = useLocalStorageState(
    "filter.workOrderStatuses",
    []
  );

  const [workOrderCategories, setWorkOrderCategories] = useLocalStorageState(
    "filter.workOrderCategories",
    []
  );

  return (
    <TableFilterProvider
      value={{
        filterModalActive,
        setFilterModalActive,

        status,
        setStatus,

        isGeo,
        setIsGeo,

        hasNoRentDueDate,
        setHasNoRentDueDate,

        locations,
        setLocations,

        districts,
        setDistricts,

        collectionLocations,
        setCollectionLocations,

        activityTypes,
        setActivityTypes,

        checksOnly,
        setChecksOnly,

        hideGeoPayments,
        setHideGeoPayments,

        hideDirectDepositPayments,
        setHideDirectDepositPayments,

        sortLastUaDate,
        setSortLastUaDate,

        workOrderPriorities,
        setWorkOrderPriorities,

        workOrderStatuses,
        setWorkOrderStatuses,

        workOrderCategories,
        setWorkOrderCategories,
      }}
    >
      {children}
    </TableFilterProvider>
  );
};

TableFilter.propTypes = {
  children: PropTypes.node.isRequired,
};
